<template>
    <div>
        <el-dialog :title="title" :visible.sync="addUserVisible"  width="800px"
                   :before-close="handleCancel" :close-on-click-modal="false" @open="openAddUser()">
              <div>
              <el-form label-position="right" label-width="100px" :inline="true" :model="row"
                     style="padding: 0px 70px 0px 10px;" class="demo-ruleForm">
                <el-form-item label="申请用户：">
                  <el-input v-model="row.applyby" placeholder="申请用户" disabled></el-input>
                </el-form-item>
                <el-form-item label="申请时间：">
                    <el-input v-model="row.applytime" clearable placeholder="申请时间" disabled ></el-input>
                </el-form-item>
                <el-form-item label="有效时间：">
                    <el-input v-model="row.licensetime" clearable placeholder="申请时间" disabled ></el-input>
                </el-form-item>
                <el-form-item label="申请原因：">
                    <el-input type='textarea' style="width:530px" v-model="row.applycause" clearable placeholder="申请原因" disabled></el-input>
                </el-form-item>
              </el-form>
              </div>
            <div slot="footer" class="dialog-footer">
                <el-button type="primary" @click="pass(dataList)">通过</el-button>
                <el-button type="danger" @click="reject">拒绝</el-button>
                <el-button type="info" @click="handleCancel">取消</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
  name: 'Adddesignerpproval',
  props: {
    addUserVisible: Boolean,
    data: [Object, Boolean],
    title: String,
    row: [Object, Array],
    dataList: [Object, Array]
  },
  data () {
    return {
    }
  },
  methods: {
    openAddUser () {},
    pass (dataList) {
      console.log('dataList', dataList)
      this.axios.post('/users/passlicense/', {
        unitid: localStorage.getItem('unit_id'),
        applyid: this.row.id,
        approvalby: this.row.applyby,
        employeeid: localStorage.getItem('user_id'),
        rid: dataList
      }).then((res) => {
        this.$message({
          type: 'success',
          message: '通过成功'
        })
        this.$emit('cancel')
      }).catch((error) => {
        console.log(error)
      })
    },
    handleCancel () {
      this.$emit('cancel')
    },
    reject () {
      this.$prompt('拒绝原因', '审批拒绝', {
        confirmButtonText: '确认拒绝',
        cancelButtonText: '取消',
        inputPattern: '',
        inputErrorMessage: '内容不能为空',
        inputValidator: (value) => {
          if (!value) {
            return '输入不能为空'
          }
        }
      }).then(({ value }) => {
        this.axios.post('/users/rejectlicense/', {
          unitid: localStorage.getItem('unit_id'),
          applyid: this.row.id,
          approvalby: this.row.applyby,
          employeeid: localStorage.getItem('user_id'),
          applycause: value
        }).then((res) => {
          this.$message({
            type: 'success',
            message: '拒绝成功'
          })
          this.$emit('cancel')
        }).catch((error) => {
          console.log(error)
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消拒绝'
        })
      })
    }
  }
}
</script>

<style scoped>
     .Attopic{display:block; left: 56px; position: absolute; top: 68px; z-index: 999; background: #fff; border: 1px solid #ccc; padding: 2px; width: 180px; height: 100px; overflow-x:hidden; overflow-y:hidden;}

</style>
